.Main {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.mvp{
  color: red; 
  font-size: medium;
  margin:auto;
  display: flex;
justify-content: center;
}

.mainHeader{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  font-weight: bold;
  color:black;
  margin:auto;
  display: flex;
justify-content: center;
}


.headerLogo{
max-width: 249px;
max-height: 108px;
margin:auto;
margin-top:  10px;
display:flex;
}


.headerText{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  font-weight: bold;
  color:black;
  margin:auto;
  display: flex;
  justify-content: center;
font-size: 6vw;
line-height: 6vw;
}


.child{
  display: inline-block;
  padding:5px;
}


main {
  margin:auto;
  min-height: 80vh;
}


h3 {
  padding: 1rem 0;
}

h1 {
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2 {
  font-size: 1.4rem;
  padding: 0.5rem;
}

.weak{
 color: red; 
 font-size: small;
}


/* carousel */
 .carousel{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width:1000px;
  margin: auto !important;
} 

.carousel.carousel-slider {
  margin: auto !important;
}

 .carousel .thumbs{
  margin: auto !important;
  padding: 0px !important;
} 

.carousel .thumb:hover{
  border: 1px  solid darkgray !important;
}

.carousel .thumb.selected{
  border: 1px  solid black !important;
}


.carousel .thumb{
  border-radius: 12px !important;
}

/* left arrows */
.carousel .control-prev.control-arrow{
  border-radius: 12px !important;
}

  .carousel .control-prev.control-arrow::before{
  border-right: 8px solid black !important;
} 


.carousel .control-prev.control-arrow:hover{
  background-color: #e8e8e8 !important;
  border: 1px solid black !important;
color:red;
  opacity: 1 !important;

}

/*right arrows */
.carousel .control-next.control-arrow{
  border-radius: 12px !important;
}

.carousel .control-next.control-arrow::before {
    border-left: 8px solid black !important;
    opacity: 1 !important;
}


.carousel .control-next.control-arrow:hover{
  background-color: #e8e8e8 !important;
  border: 1px solid black !important;
}






.centerText{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin: auto;
  height: 200px;
  font-size: 2rem;

}

.gridHeader {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1%;
}

.gridHeader > div {
  margin: 5px;
  line-height: 75px;
}



/*for responsive ads*/
.parent {
  position: relative;
  width: 90%;
  margin:auto;
}

.responsive {
  max-width: 100%;
  max-height: 100%;
}
.center {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0%);
  margin-top: 4%;
  margin-bottom: 4%;
  max-width: 100%;
}


.mainHeader {
  text-align: center;
  font-weight: bolder;
  font-size: 6vw;
}



.renderHeader {
  text-align: center;
  background: #e8e8e8;
  color: black;
  font-size: 2rem;
  font-weight: 600;
  margin-top: 2%;
  margin-bottom: 1%;
  margin-left: 3%;
  margin-right: 3%;
  border-radius: 12px;
  border: 1px solid black;
}



.grid-container{
  display: grid;
  grid-template-columns: 60% 40% ;
  width: 95%;
  margin: auto;
}

.grid-item{
  justify-content: center;
  align-items: center;
  margin: auto;
}

.keyboardFrame {
  position: relative;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}

.keyboardFrameImage {
  position: relative;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
}


.renderSubHeader {
  color:#000000;
  display: block;
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: none;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.gallery{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  color:#000000;
  font-size: 2.5em;
  margin:auto;
  margin-top: 5%;
  font-weight: bold;
}

.renderSubHeaderDisabled {
  display: block;
  font-size: 1.5em;
  margin-top: 1em;
  margin-bottom: none;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  opacity: 0.5;
}

.button {
  margin-top: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom: 0.5%;
}

.proceedToCheckoutEnabled{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #000000;
  background-color: #e8e8e8;
  border-radius: 12px;
  font-size: 1.5em;
  font-weight: bold;
  opacity: 1;
  padding: 2%;
  margin-top: 5%;

}

.proceedToCheckoutDisabled{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #000000;
  background-color: #e8e8e8;
  border-radius: 12px;
  font-size: 1.5em;
  font-weight: bold;
  opacity: 0.5;
  padding: 2%;
  margin-top: 5%;
  cursor:  not-allowed !important;
  
}

span.logoSpan{
vertical-align: bottom;
}

.stripLogo{
  margin: 10px;
  margin-bottom: 2px;
  max-width: 130px;
  opacity: 0.7;
}


.stripLogoDisabled{
  margin: 10px;
  margin-bottom: 2px;
  max-width: 130px;
  opacity: 0.2;
}

.colorSelectorButton {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  margin: 3px;
  border-width: 2px;
  box-shadow: 0px 3px 3px lightgrey, inset -2px 0 0 black, inset 0 -2px 0 black, inset 2px 0 0 black, inset 0 2px 0 black;
}

.colorSelectorButtonDisabled {
  width: 50px;
  height: 50px;
  border-radius: 12px;
  margin: 3px;
  border-width: 2px;
  box-shadow:inset -2px 0 0 darkGrey, inset 0 -2px 0 darkGrey, inset 2px 0 0 darkGrey, inset 0 2px 0 darkGrey;
  opacity: 0.5;

}

.checkoutMessage{
  font-size: smaller;
}

.thumbnailDisabled {
  opacity: 0.5;
}


.faqCss {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  width: 90%;
  margin: auto;
  font-weight: 400;
  color: black;
  font-size: medium;
}

.ptchob {
  padding: 2%;
  margin-top: 5%;
  border-radius: 12px;
  font-size: 1.5em;
  font-weight: bold;
}

.ptchobDisabled {
  padding: 2%;
  margin-top: 5%;
  border-radius: 12px;
  font-size: 1.5em;
  font-weight: bold;
  opacity: 0.5;
}


.specsTable{
  width: 90%;
  margin:auto;
  color:black;
  font-weight: 400;
  font-size: medium;
  margin-bottom: 4%;
}

.specsTable .cellLeft{
  font-weight: 400;
  font-size: medium;
  padding-left: 1%;
  padding:5px;
}

.specsTable .cellRight{
  font-weight: 400;
  font-size: medium;
  padding-right: 1%;
  padding:5px;
}

.specsTable .cellColoredLeft{
  font-weight: 400;
  font-size: medium;
  background-color: #f8f8f8;
  border-top-left-radius: 8px; 
  border-bottom-left-radius: 8px;
  padding-left: 1%;
  padding:5px;
}

.specsTable .cellColoredRigth{
  font-weight: 400;
  font-size: medium;
  background-color: #f8f8f8;
  border-top-right-radius: 8px; 
  border-bottom-right-radius: 8px;
  padding-right: 1%;
  padding:5px;

}

.specsTableheader{
/*not actually part of the table and is also being used for faq header*/
text-align: left;
background: #e8e8e8;
color: black;
padding-left: 1%;
font-size: 2rem;
font-weight: 600;
margin-top: 2%;
margin-bottom: 0%;
margin-left: 3%;
margin-right: 3%;
border-radius: 12px;
padding-top:4px;
padding-bottom:4px;
padding-left: 20px;
padding-right: 20px;
border: 1px solid black;
}

.modal-content{
  border-radius: 10px;
}

.modal-header button{
  border-radius: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
 .form-control{
border-radius: 10px; 
}



/*------invisble buttons on image --------*/

.box{
  position: relative;
  max-width: 1024px;
}

.box img{
  display: block;
}

.box .static {
  /* The static image helps responsively resizing the bounding box correctly */ 
  width: 100%;
  height: auto;
}

.box .layer{
  position: absolute;
  height: 100%;
  top: 0px;
}


.box .oneL { position: absolute; top: 20.83%; left: 15.63%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .twoL { position: absolute; top: 28.13%; left: 15.43%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .threeL { position: absolute; top: 35.07%; left: 14.75%; width: 4.39%; height: 5.73%;opacity: 0;}
.box .fourL { position: absolute; top: 40.97%; left: 13.67%; width: 4.39%; height: 6.6%;opacity: 0;}
.box .tenL { position: absolute; top: 16.49%; left: 40.43%; width: 4.39%; height: 7.29%;opacity: 0;}
.box .elevenL { position: absolute; top: 26.04%; left: 40.23%; width: 4.39%; height: 8.33%;opacity: 0;}
.box .twelveL { position: absolute; top: 39.93%; left: 30.76%; width: 4.39%; height: 11.28%;opacity: 0;}
.box .thirteenL { position: absolute; top: 41.67%; left: 36.13%; width: 4.39%; height: 10.42%;opacity: 0;}
.box .fourteenL { position: absolute; top: 54.69%; left: 35.16%; width: 4.39%; height: 7.81%;opacity: 0;}
.box .fifteenL { position: absolute; top: 50.35%; left: 40.53%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .sixteenL { position: absolute; top: 63.37%; left: 37.11%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .seventeenL { position: absolute; top: 59.9%; left: 41.5%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .oneR { position: absolute; top: 20.83%; left: 79.39%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .twoR { position: absolute; top: 28.13%; left: 79.39%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .threeR { position: absolute; top: 35.07%; left: 79.88%; width: 4.39%; height: 5.73%;opacity: 0;}
.box .fourR { position: absolute; top: 40.97%; left: 81.54%; width: 4.39%; height: 6.6%;opacity: 0;}
.box .tenR { position: absolute; top: 16.49%; left: 54.69%; width: 4.39%; height: 7.29%;opacity: 0;}
.box .elevenR { position: absolute; top: 26.04%; left: 54.69%; width: 4.39%; height: 8.33%;opacity: 0;}
.box .twelveR { position: absolute; top: 39.93%; left: 63.96%; width: 4.39%; height: 11.28%;opacity: 0;}
.box .thirteenR { position: absolute; top: 41.67%; left: 59.08%; width: 4.39%; height: 10.42%;opacity: 0;}
.box .fourteenR { position: absolute; top: 54.69%; left: 60.06%; width: 4.39%; height: 7.81%;opacity: 0;}
.box .fifteenR { position: absolute; top: 50.35%; left: 54.69%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .sixteenR { position: absolute; top: 63.89%; left: 58.11%; width: 4.39%; height: 6.94%;opacity: 0;}
.box .seventeenR { position: absolute; top: 59.9%; left: 53.71%; width: 4.39%; height: 6.94%;opacity: 0;}

@media screen and (max-width: 1280px) { 
  .grid-container{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: auto;
  }
  
  .grid-item{
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  
}